
// Vue reactivity
import { defineComponent, onMounted, ref, watchEffect } from 'vue';
// icons
import { person, call, mail } from 'ionicons/icons';
// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonSpinner, IonItem, IonLabel, IonIcon,
        IonButtons, IonButton, IonInput, IonGrid, IonCol, IonRow, IonText,
        modalController, loadingController,
        toastController, 
        alertController } from '@ionic/vue';
// API services
import AuthService from '@/services/AuthService';
import firebase from 'firebase/app';
import 'firebase/auth';
import { utils } from '@/composables/utils';
import { useI18n } from 'vue-i18n';
import UserService from '@/services/UserService';
import { useStore } from 'vuex';
export default defineComponent({
  name: 'SMSLoginModal',
  props: {
    editingUser: null,
  },
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter,
                IonSpinner, IonItem, IonLabel, IonIcon, IonButtons, IonButton, IonInput, IonText,
                IonGrid, IonCol, IonRow, },
  setup(props) {
    // 1. declare state variables (ref to make them reactive)
    const store = useStore();
    const phone = ref("");
    const isInvalidPhone = ref(false);
    const isEditingPhone = ref(false);
    if (props.editingUser) { // editing post
      isEditingPhone.value = true;
      watchEffect(() => {
        phone.value = props.editingUser.value.phone;
      })
    }
    const window: any = {
      recaptchaVerifier: undefined,
      confirmationResult: undefined,
      verificationId: undefined,
    };
    onMounted(() => {
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('grecaptcha-div', {
        'size': 'invisible',
        'callback': (res: any) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        }
      });
    })
    // methods or filters
    const { t } = useI18n();
    const { presentAlert, closeModal, } = utils();
    const verifyCode = async (verificationCode: any, alert: any = null) => {
      const loading = await loadingController.create({});
      await loading.present();
      try {
        if (isEditingPhone.value == true) {
          const phoneCredential = firebase.auth.PhoneAuthProvider.credential(window.verificationId, verificationCode);
          await firebase.auth().currentUser?.updatePhoneNumber(phoneCredential);
          const res = await UserService.updateLoggedInUser({ ...props.editingUser.value, phone: phone.value }, props.editingUser.value);
          store.commit('receiveUser', res);
          firebase.auth().currentUser?.reauthenticateWithCredential(phoneCredential);
        } else {
          await window.confirmationResult.confirm(verificationCode);
        }
        loading.dismiss();
        if (alert) alert.dismiss();
        closeModal();
      } catch (e) {
        loading.dismiss();
        if (e.code == "auth/invalid-verification-code") { // wrong verification code
          presentAlert(t('RegisterPage.invalidVerificationCode'))
        } else {
          presentAlert(e.message);
        }
      }
    }
    const doSMSLogin = async (phone: any) => {
      const loading = await loadingController.create({});
      await loading.present();
      const res = isEditingPhone.value || await AuthService.checkPhoneExists(phone);
      if (res === true) {
        try {
          // verify the phone
          const appVerifier = window.recaptchaVerifier;
          const verifyingPhone = `+852${phone}`;
          if (isEditingPhone.value == true) {
            const provider = new firebase.auth.PhoneAuthProvider();
            window.verificationId = await provider.verifyPhoneNumber(verifyingPhone, appVerifier);
          } else {
            window.confirmationResult = await firebase.auth().signInWithPhoneNumber(verifyingPhone, appVerifier);
          }
          const alert = await alertController.create({
            backdropDismiss: false,
            header: t('RegisterPage.verifyingMobileNumber'),
            subHeader: `${t('RegisterPage.verifyMobileNumberInstruction')} (${phone}).`,
            inputs: [
              {
                name: 'verificationCode',
                type: 'text',
                placeholder: t('RegisterPage.verificationCode'),
                attributes: {
                  inputmode: 'numeric',
                }
              },
            ],
            buttons: [
              {
                text: t('cancel'),
                role: 'cancel',
                cssClass: 'secondary',
              },
              {
                text: t('RegisterPage.verify'),
                handler: (value) => {
                  if (value.verificationCode) {
                    verifyCode(value.verificationCode, alert);
                  }
                  return false; // not closing the alert
                },
              },
            ],
          });
          await alert.present();
        } catch (e) {
          if (e.code == "auth/invalid-phone-number") {
            presentAlert(t('enterValidHKMobileNumber')); // invalid phone number
          } else {
            presentAlert(e.message);
          }
        }
      } else {
        isInvalidPhone.value = true;
      }
      loading.dismiss();
    }
    // 3. return variables & methods to be used in template HTML
    return {
      t,
      
      // icons
      mail, person, call,
      // variables
      phone, isInvalidPhone, isEditingPhone,
      // methods
      doSMSLogin, closeModal,
    }
  }
});
