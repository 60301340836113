
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent,
        IonGrid, IonCol, IonRow, IonButtons, IonButton, IonIcon,
        IonCard, IonCardHeader, IonCardContent, IonItem, IonBackButton,
        IonLabel, IonInput, IonSelect, IonSelectOption,
        loadingController, alertController, modalController, } from '@ionic/vue';
import LogoImg from '@/components/LogoImg.vue';

// icons
import { logIn, qrCodeOutline, scan, close, mail, key, globe, } from 'ionicons/icons';
import { ref } from 'vue';

// services
import AuthService from '@/services/AuthService';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { utils } from '@/composables/utils';
import SMSLoginModal from '@/components/templates/SMSLoginModal.vue';

export default {
  name: 'LoginPage',
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonPage,
                IonGrid, IonCol, IonRow, IonButtons, IonButton, IonIcon,
                IonCard, IonCardHeader, IonCardContent, IonItem, IonBackButton,
                IonLabel, IonInput, IonSelect, IonSelectOption, LogoImg, },
  setup() {
    const loginEmail = ref("");
    const loginPsw = ref("");
    const pswInput = ref(null);

    // methods
    const { t, locale } = useI18n();
    const router = useRouter();
    const redirectPath = router.currentRoute.value.query.redirectPath;
    const { presentToast, presentAlert, focusInputField, } = utils();

    // update user information like first name and last name
    const openSMSLoginModal = async () => {
      const modal = await modalController.create({
        component: SMSLoginModal,
        cssClass: 'small-modal',
      });
      return modal.present();
    }
    const doLogin = async (email: string, password: any) => {
      if (email && password) {
        const loading = await loadingController.create({});
        await loading.present();
        try {
          if (email.includes("@")) { // normal email login
            await AuthService.doLogin(email, password);
          } else {
            await AuthService.doPhoneLogin(email, password); // phone login
          }
        } catch (e) {
          console.log(e.code);
          presentAlert(t(e.message)); // wrong email or wrong password
        }
        loading.dismiss();
      } else {
        presentToast(t("RegisterPage.enterEmailAndPassword"));
      }
    };
    const goToForgotPasswordPage = () => {
      router.push('/forgot-password'); // go to forgot password page
    }
    const goToRegisterPage = () => {
      const location = { path: '/register' };
      if (redirectPath) location['query'] = { redirectPath };
      router.push(location);
    }
    
    return {
      t,
      // icons
      logIn, qrCodeOutline, scan, close, mail, key, globe,
      
      // variables
      locale, loginEmail, loginPsw,
      pswInput,

      // methods
      doLogin, openSMSLoginModal,
      goToForgotPasswordPage, goToRegisterPage,
      focusInputField,
    }
  },
}
